<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Global Rose</title>
</head>
<body>
    <div class="baseLogin">
        <div class="overlay"></div>
        <div class="logins"> 
          <form [formGroup]="form" (ngSubmit)="submitForm()">
                
                <div class="logo">
                  <img src="https://cdn.globalrose.com/assets/img/logo/globalrose_logo.png" width="250px">
                </div>

                <mat-form-field>
                  <input type="email" id="email" formControlName="email" matInput placeholder="Email" autocomplete="off">
                  <span matSuffix>
                      <mat-icon>alternate_email</mat-icon>
                  </span>
                  <div *ngIf="form?.get('email').invalid && form.get('email').touched" class="msgError">
                    <p>The email is required.</p>
                  </div>
                </mat-form-field>

                <mat-form-field>
                  <input type="password" id="password" formControlName="password" matInput placeholder="password" autocomplete="off" #password>
                  <span matSuffix>
                      <mat-icon (click)="showPassword()">{{iconPasw}}</mat-icon>
                  </span>
                  <div *ngIf="form.get('password').invalid && form.get('password').touched" class="msgError">
                    <p>The Password is required.</p>
                  </div>
                </mat-form-field>

                <button mat-icon-button color="warn" class="btnRep">
                  <button type="submit" [disabled]="form.invalid" mat-raised-button color="warn">Sign in</button>
                </button>
          </form>
        </div>
    </div>
</body>
</html>