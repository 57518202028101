import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const debug = false;
let MENUITEMS = Array();

if(debug){
  MENUITEMS = [
    //{ state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
    { state: 'button', type: 'link', name: 'Buttons', icon: 'crop_7_5' },
    { state: 'grid', type: 'link', name: 'Grid List', icon: 'view_comfy' },
    { state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
    { state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
    { state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
    { state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
    { state: 'expansion', type: 'link', name: 'Expansion Panel', icon: 'vertical_align_center' },
    { state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
    { state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
    { state: 'progress-snipper', type: 'link', name: 'Progress snipper', icon: 'border_horizontal'},
    { state: 'progress', type: 'link', name: 'Progress Bar', icon: 'blur_circular' },
    { state: 'dialog', type: 'link', name: 'Dialog', icon: 'assignment_turned_in' },
    { state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
    { state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
    { state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
    { state: 'slide-toggle', type: 'link', name: 'Slide Toggle', icon: 'all_inclusive' }
  ];
}else{
  if(localStorage.getItem('Globalrose_user_role')){
    let rol = localStorage.getItem('Globalrose_user_role')
    MENUITEMS = Array();
  
    //MENUITEMS.push({ state: 'dashboard', name: 'Tool Pack', type: 'link', icon: 'home_repair_service' })
  
    if(rol!.indexOf('GenerateOrders') > -1){
      MENUITEMS.push({ state: 'generate-orders', type: 'link', name: 'Generate Orders', icon: 'grading' })
    }
  
    if(rol!.indexOf('FedExTools')  > -1){
      MENUITEMS.push({ state: 'base-fedex', type: 'link', name: 'FedEx Tools', icon: 'local_shipping' })
    }
  
    if(rol!.indexOf('GrReports')  > -1){
      MENUITEMS.push({ state: 'gr-reports', type: 'link', name: 'GR Reports', icon: 'insert_chart' })
    }
  
    if(rol!.indexOf('UpdatePrice')  > -1){
      MENUITEMS.push({ state: 'miva-prices', type: 'link', name: 'Update Prices', icon: 'payments' })
    }
  
    if(rol!.indexOf('BogotaTools')  > -1){
      MENUITEMS.push({ state: 'base-bogota', type: 'link', name: 'Bogotá Tools', icon: 'travel_explore' })
    }
  
    if(rol!.indexOf('TrackingNumbers')  > -1){
      MENUITEMS.push({ state: 'base-tracking-numbers', type: 'link', name: 'Tracking Numbers', icon:'23mp' })
    }

    if(rol!.indexOf('CouponsZone')  > -1){
      MENUITEMS.push({ state: 'app-base-cupons', type: 'link', name: 'Coupons Zone', icon:'request_quote' })
    }

    if(rol!.indexOf('ConnieSystem')  > -1){
      MENUITEMS.push({ state: 'app-base-connie-system', type: 'link', name: 'Connie System', icon:'smart_toy' })
    }
  
    if(rol!.indexOf('Admin')  > -1){
      MENUITEMS = [
        { state: 'dashboard', name: 'Tool Pack', type: 'link', icon: 'home_repair_service' },
        { state: 'generate-orders', type: 'link', name: 'Generate Orders', icon: 'grading' },
        { state: 'base-fedex', type: 'link', name: 'FedEx Tools', icon: 'local_shipping' },
        { state: 'gr-reports', type: 'link', name: 'GR Reports', icon: 'insert_chart' },
        { state: 'miva-prices', type: 'link', name: 'Update Prices', icon: 'payments' },
        { state: 'base-bogota', type: 'link', name: 'Bogotá Tools', icon: 'travel_explore' },
        { state: 'base-tracking-numbers', type: 'link', name: 'Tracking Numbers', icon:'23mp' },
        { state: 'app-base-cupons', type: 'link', name: 'Coupons Zone', icon:'request_quote' },
        { state: 'app-base-admin-zone', type:'link', name:'Admin Zone', icon:'verified_user' },
        { state: 'app-base-connie-system', type: 'link', name: 'Connie System', icon:'smart_toy' },
        { state: 'reviews', type: 'link', name: 'Reviews', icon:'star' }
      ]; 
    }
  }

}

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}